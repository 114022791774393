<script>
  export default {
    methods:{
      findArrItemVal:(arr,val,findattr,outputattr)=>{
        for (const i in arr) {
          const item = arr[i];
          const keylist = Object.keys(item);
          for (const j in keylist) {
            const key = keylist[j];
            // console.log(key+","+item[key]);
            if(key==findattr&&item[key]==val){
              return item[outputattr];
            }
          }
        }
      }
    }
  }

</script>

<template>

</template>

<style scoped>

</style>