<template>
  <div class="hello">
    <div class="banner">
      <img class="canva-elem animate__animated animate__fadeInTopLeft animate__slow animate__repeat-1" id="left-top" src="../assets/home/left-top.svg"/>
      <img class="canva-elem animate__animated animate__fadeInTopRight animate__slower animate__repeat-1" id="cloud-4" src="../assets/home/cloud-4.svg" />
      <img class="canva-elem animate__animated animate__fadeInRight animate__slower animate__repeat-1" id="dragon"  src="../assets/home/dragon.png"/>
      <img class="canva-elem animate__animated animate__fadeInBottomRight animate__slower animate__repeat-1" id="cloud-1" src="../assets/home/cloud-1.svg" />
      <img class="canva-elem animate__animated animate__fadeInBottomLeft animate__slower animate__repeat-1" id="cloud-2" src="../assets/home/cloud-2.svg" />
      <img class="canva-elem animate__animated animate__fadeInBottomRight animate__slower animate__repeat-1" id="cloud-3" src="../assets/home/cloud-3.svg" />
      <img class="canva-elem animate__animated animate__fadeInBottomLeft animate__slower animate__repeat-1" id="left-bottom" src="../assets/home/left-bottom.svg" />
      <img class="canva-elem animate__animated animate__zoomIn animate__slower animate__repeat-1" id="center" src="../assets/home/center.png" />
    </div>

  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data(){return{
    jzstart:true
  }},
  props: {
    msg: String
  },
  components:{
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "animate.css";
body {

  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }

  .banner {
    .canva-elem {
      position: absolute;
    }

    #dragon {

      width: 46vw;
      right: -15.7vw;
      bottom: 0vh;
    }

    #left-top {
      width: 10vw;
      left: 0;
    }

    #cloud-1 {
      right: 0;
      bottom: 0;
      width: 76vw;
    }

    #cloud-2 {
      left: 0;
      bottom: 0;
      width: 76vw;
    }

    #cloud-3 {
      right: 0;
      bottom: 0;
      width: 15vw;
    }

    #cloud-4 {
      right: 0;
      top: 0;
      width: 11vw;
    }

    #left-bottom {
      width: 24vw;
      left: 0;
      bottom: 0;
    }

    #center {
      width: 60vw;
      top: calc(50vh - 20vw);
      left: 12vw;
    }
  }
  #top-nav-bar{
    height: 7vh;
    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
    position: fixed;
    top:0;
    box-sizing: border-box;
    background: none;
  }
}
</style>
