<template>
  <div class="icp"><div class="copyright">{{`© ${year} ${author} ` }}</div><div class="record"><a href="http://beian.miit.gov.cn/" target="_blank">{{ record }}</a></div></div>
</template>

<script setup>
let year = "1994-"+new Date().getFullYear();        // 一般都是最新的一年
let author = '丁伟 David Ding';					// 作者名
let record = '苏ICP备2024070405号-1';			// 备案号
</script>

<style lang="scss" scoped>
.icp {
	position: absolute;
	bottom: 0;
	right: 0;
	margin: 10px 35vw;
  width: 30vw;
	height: 36px;
	white-space: pre;
	text-align: center;
	color: gray;
	z-index: 1000;
  .copyright{
    float: left;
    width: 47%;
    text-align: right;
    line-height: 36px;
  }
  .record{
    float: left;
    width: 53%;
    text-align: left;
    line-height: 36px;
    a {
      color: gray;
      text-decoration: none;

      :hover {
        color: aqua;
        text-decoration: none;
      }
    }
  }
}
@media screen and (max-width: 430px) {
  .icp {
    font-size: 12px;
    margin: 10px 25vw;
    width: 50vw;
    .record{
      float: unset;
      text-align: center;
      width: 100%;
      line-height: 16px;
    }
    .copyright{
      float:unset;
      text-align: center;
      width: 100%;
      line-height: 16px;
    }
  }
}
</style>
