import { createApp} from 'vue';
import ArcoVue from "@arco-design/web-vue";
import App from './App.vue';
import "@arco-design/web-vue/dist/arco.css";
import Router from "@/route/routes.js";

const app = createApp(App);
app.use(ArcoVue);
app.use(Router);
app.mount('#app');
