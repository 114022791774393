<script>
import confluence from "@/assets/home/Confluence.png";
import jira from "@/assets/home/Jira.png";
export default {
  props:["pageinfo"],
  data(){
    return {
      pInfo:{},
      isBlockedByCORS:false,
      imgsrc:"",
      confluencesrc:confluence,
      jirasrc:jira
    }
  },
  methods:{
    checkURL:function(){
      switch(this.pInfo.title){
        case "Confluence" : {
          this.isBlockedByCORS=true;
          this.imgsrc=this.confluencesrc;
          this.$message.warning('Confluence Page is blocked by CORS. Please press "Explore" to open Confluence page with a new tab. ');
        }break;
        case "Jira":{
          this.isBlockedByCORS=true;
          this.imgsrc=this.jirasrc;
          this.$message.warning('Jira Page is blocked by CORS. Please press "Explore" to open Jira page with a new tab. ');
        }break;
        default:{
          this.isBlockedByCORS = false;
        }
      }
    }
  }
  ,watch:{
    pageinfo:function(newVal){
      this.pInfo = newVal;
      this.checkURL();
    }
  },mounted() {
    this.checkURL();
  }
}

</script>

<template>
    <a-card class="Card-Page" :title="pageinfo.title" :body-style="{
       height: 'calc(100% - 46px)',
        'box-sizing': 'border-box'
    }">
      <template #cover v-if="isBlockedByCORS">
        <div
            :style="{
          height:'100%',
          overflow: 'hidden'
        }"
        >
          <img
              :style="{ width: '100%'}"
              alt="dessert"
              :src="imgsrc"
          />
        </div>

      </template>
      <template #extra>
        <a-link :href="pageinfo.src" target="_blank">Explore</a-link>
      </template>
      <iframe :src="pageinfo.src" id="linkpage" v-if="!isBlockedByCORS"></iframe>

    </a-card>
</template>

<style lang="scss" scoped>
.Card-Page{
  width: 100%;
  height: 100%;
  display: block;
  .arco-card-cover{
    height: calc( 100% - 60px );
  }
  .arco-card-body {
    #linkpage {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
</style>