<script>
export default {
  data(){
    return{
    }
  },
  props:["itemid","itemlink","itemname"]
}

</script>

<template>
  <a-menu-item :key="itemid" :mkey="itemid">
    <router-link :to="itemlink">{{itemname}}</router-link>
  </a-menu-item>
</template>

<style lang="scss" scoped>
.arco-menu-item {
  background-color: unset!important;
  a[nid] {
    position: relative;
    margin-top: 4vh;
    display: block;
    width: 120px;
    float: left;
    font-size: 1.7vh;
    text-decoration: none;
    color: black;


  }
  :hover {
    color: #313131;
  }
}
</style>