<script>

import Empty from "@/components/Element/DataShow/Empty.vue";

export default {
  components: {Empty}
}

</script>

<template>
  <Empty msg="Under Construction! Please wait!"></Empty>
</template>

<style lang="scss">
  .arco-empty{
    height: 100vh;
    padding: calc( 50vh - 35px ) 0;
     .arco-empty-image{
       svg{
         width: 100px;
         height: 100px;
       }
     }
    .arco-empty-description{
      font-size: 30px;
    }
  }
</style>