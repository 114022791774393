<template>
    <iframe :src="framesrc" class="mydeck"></iframe>
    <a-list class="doclist">
      <template #header>
        Reference Doc
      </template>
      <a-list-item v-for="(ditem,dkey) in doclist">
        <a-link href="javascript:void(0);" :src="ditem.src" @click="showDoc($event);">{{ditem.name}} </a-link>
      </a-list-item>
    </a-list>
</template>
<script>
export default {
  data() {
    return {
      activeKey: 1,
      framesrc: "/files/Projects/Data Cloud (Phase I Recommendation Engine) System Architecture Design.pdf",
      doclist: [{
        name: "Data Cloud_Design",
        src: "/files/Projects/Data Cloud (Phase I Recommendation Engine) System Architecture Design.pdf"
      },{
        name: "Data Cloud_Project Repository",
        src: "https://gitlab.ideartifact.cn/root/data-cloud"
      }, {
          name: "One Meta Cloud_Design",
          src: "https://uwtkirl2w48.feishu.cn/wiki/TyGPwFSYli1ly4k8kpbcUOD9nwd"
        },{
          name: "One Meta Cloud_Project Repository",
          src: "https://gitlab.ideartifact.cn/root/OneMetaCloud"
        }]
    }
  },
  methods:{
    showDoc:function (e){
      console.log(this.activeKey);
      var tsrc = e.currentTarget.getAttribute("src");
      var csrc = this.framesrc;
      if(tsrc!=csrc){
        this.framesrc = e.currentTarget.getAttribute("src");
      }
    }
  },
  mounted() {
    console.log(this.activeKey);
    this.activeKey=1;
  }
}
</script>

<style lang="scss">


    .mydeck {
      height: 100%;
      width: calc(100% - 300px);
      border: 0px;
    }

    .doclist {
      float: left;
      width: 300px;
      text-align: left;
    }


</style>
