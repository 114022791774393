<script>
import NavPage from "@/components/View/Template/NavPage.vue";
import NavPage_Page from "@/components/View/Template/NavPage_Page.vue";
export  default {
  components: {NavPage},
  data(){
    return {
      navjson:[
        {
          key:4,
          path:'/personal-gallery/resume',
          name:'Resume',
          name_cn:"简历",
          component:NavPage_Page
        }
        ,{
          key:5,
          path:'/personal-gallery/works',
          name:'Ideas & Works',
          name_cn:"创意&作品",
          component:NavPage_Page
        },{
          key:6,
          path:'/personal-gallery/projects',
          name:'Projects',
          name_cn:"开源项目",
          component:NavPage_Page
        },{
          key:1,
          path:'/personal-gallery/gitlab',
          name:'Gitlab',
          name_cn:"Gitlab",
          component:NavPage_Page,
        },
        {
          key:2,
          path:'/personal-gallery/confluence',
          name:'Confluence',
          name_cn:"Confluence",
          component:NavPage_Page
        },{
          key:3,
          path:'/personal-gallery/jira',
          name:'Jira',
          name_cn:"Jira",
          component:NavPage_Page
        }
        ,{
          key:7,
          path:'/personal-gallery/jenkins',
          name:'Jenkins',
          name_cn:"Jenkins",
          component:NavPage_Page
        }
      ]
    }
  }
}
</script>

<template>
  <NavPage :navjson="navjson"></NavPage>
</template>

<style scoped>

</style>


