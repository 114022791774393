<script>
import FirstLevelNavItem from "@/components/Element/Navi/Top/FirstLevelNavItem.vue";
import Global from "@/Methods/Global.vue";
import {chromeOS} from "../../../../../old/public/js/CodeMirror/src/util/browser";
export default {
  components: {FirstLevelNavItem},
  props:["linklist"],
  data(){return{
    selectedKeys:[""]
  }},
  mounted() {
    var currentRouter = localStorage.$currentRouter;
    var patharray = currentRouter.split("/");
    var key = Global.methods.findArrItemVal(this.linklist,currentRouter,"link","key");
    if(!key){
      if(patharray.length==3&&currentRouter.indexOf("personal-gallery")>-1){
        this.selectedKeys = ""+2;
      }else{
        this.selectedKeys = ""+1;
      }
    }else{
      this.selectedKeys = ""+key;
    }
  }
};
</script>

<template>
<div class="top-nav">
  <a-menu mode="horizontal" v-model:selected-keys="selectedKeys">
    <FirstLevelNavItem v-for="item in linklist" :key="item.key" :itemid="item.key" :itemlink="item.link" :itemname="item.name"></FirstLevelNavItem>
  </a-menu>

</div>



</template>

<style lang="scss">
.top-nav{
  box-sizing: border-box;
  width: 100%;
  background:none;
}
</style>

