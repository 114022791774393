import Index from "@/components/Index.vue";
import PersonalGallery from "@/components/View/PersonalGallery.vue";
import AboutMe from "@/components/View/AboutMe.vue";
import { createRouter, createWebHistory} from 'vue-router'
import NavPage_Page from "@/components/View/Template/NavPage_Page.vue";
import Works from "@/components/View/PersonalGallery/Works.vue";
import Projects from "@/components/View/PersonalGallery/Projects.vue";


export default createRouter({
    history:createWebHistory(),
    routes:[
        {
            path:'/',
            name:'Home Page',
            component:Index
        },
        {
            path:'/personal-gallery',
            name:'Personal Gallery',
            component:PersonalGallery,
            children:[
                {
                    key:1,
                    path:'gitlab',
                    name:'Gitlab',
                    component:NavPage_Page,
                    props:{
                        pageinfo:{
                            title:"Gitlab",
                            src:"https://gitlab.ideartifact.cn"
                        }

                    }
                },
                {
                    key:2,
                    path:'confluence',
                    name:'Confluence',
                    component:NavPage_Page,
                    props:{
                        pageinfo:{
                            title:"Confluence",
                            src:"https://confluence.ideartifact.cn"
                        }
                    }
                },{
                    key:3,
                    path:'jira',
                    name:'Jira',
                    component:NavPage_Page,
                    props:{
                        pageinfo:{
                            title:"Jira",
                            src:"https://jira.ideartifact.cn"
                        }

                    }
                }
                ,{
                    key:4,
                    path:'resume',
                    name:'Resume',
                    component:NavPage_Page,
                    props:{
                        pageinfo:{
                            title:"Resume",
                            src:"/files/Resume_David_20240219.pdf",
                        }

                    }
                }
                ,{
                    key:5,
                    path:'works',
                    name:'works',
                    component:Works,
                    props:{
                        pageinfo:{
                            title:"works",
                            src:"/personal-gallery/works"
                        }
                    }
                },{
                    key:6,
                    path:'projects',
                    name:'Projects',
                    component:Projects,
                    props:{
                        pageinfo:{
                            title:"Projects",
                            src:"/personal-gallery/projects"
                        }
                    }
                },
                {
                    key:7,
                    path:'jenkins',
                    name:'Jenkins',
                    component:NavPage_Page,
                    props:{
                        pageinfo:{
                            title:"Jenkins",
                            src:"https://jenkins.ideartifact.cn"
                        }
                    }
                }
            ]
        },
        {
            path:'/about-me',
            name:'关于我',
            component:AboutMe
        },
        {
            path:'/resume',
            name:'简历'
        }
    ]
});