<template>
  <a-modal :visible="ModalVisible" @ok="handleOk(execOK)" @cancel="handleCancel(execCancel)">
    <template #title>
      {{title}}
    </template>
    <div>{{msg}}</div>
  </a-modal>
</template>

<script>

export default {
  props:["ModalVisible","title","msg","execOK","execCancel"],
  methods:{
    handleOk:(execOK)=>{
      execOK();
    },
    handleCancel:(execCancel)=>{
      execCancel();
    }
  }
}
</script>
