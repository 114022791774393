<template>
  <a-tabs v-model:active-key="activeKey">
    <a-tab-pane v-for="(item,key) in worklist" :key="item.key" :title="item.title">
      <iframe :src="item.src" class="mydeck" :ikey="item.key"></iframe>
      <a-list class="doclist">
        <template #header>
          Reference Doc
        </template>
        <a-list-item v-for="(ditem,dkey) in item.doclist">
          <a-link href="javascript:void(0);" :src="ditem.src" @click="showDoc($event);">{{ditem.name}} </a-link>
        </a-list-item>
      </a-list>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
export default {
  data() {
    return {
      activeKey: 1,
      worklist: [{
        title: "Digital/Data Marketing",
        key: "1",
        src: "/files/Creative2nd/Ideas On Nowadays Digtial Marketing.pdf",
        doclist: [{
          name: "Performance Marketing",
          src: "/files/Creative2nd/Ideas On Nowadays Digtial Marketing.pdf"
        },{
          name: "Adobe Suite Familarity",
          src: "/files/Creative2nd/Adobe Suite Familarity.pdf"
        }]
        // ,{
        //   name: "JD Liquid_Technical Director",
        //   src: "/files/Creative2nd/Liquid_Technical Director.pdf"
        // }, {
        //   name: "JD Tezign_Martech Solution Director",
        //   src: "/files/Creative2nd/Tezign_Martech Solution Director.pdf"
        // },{
        //   name: "JD Kering_Marketing Technology Tech Lead",
        //   src: "/files/Creative2nd/JD Marketing Technology Tech Lead.pdf"
        // },{
        //   name: "JD Digital Alchemy_Tech Lead",
        //   src: "/files/Creative2nd/JD Digital Alchemy_Tech Lead.pdf"
        // }]
      }, {
        title: "McDonalds",
        key: "2",
        src: "/files/McDonalds/Competitor Analysis Report.pdf",
        doclist: [{
          name: "Competitor Analysis Report",
          src: "/files/McDonalds/Competitor Analysis Report.pdf"
        }]
      },{
        title: "Meleap Hado - CTO",
        key: "3",
        src: "/files/Meleap/Meet with Hado.pdf",
        doclist: [{
          name: "My Idea",
          src: "/files/Meleap/Meet with Hado.pdf"
        }, {
          name: "Meleap Company Instruction",
          src: "/files/Meleap/meleap企业介绍.pdf"
        }, {
          name: "Job Description",
          src: "/files/Meleap/中国CTO.pdf"
        }]
      }]
    }
  },
  methods:{
    showDoc:function (e){
      var tsrc = e.currentTarget.getAttribute("src");
      var csrc = document.querySelector("iframe[ikey='"+this.activeKey+"']").getAttribute("src");
      if(tsrc!=csrc){
        document.querySelector("iframe[ikey='"+this.activeKey+"']").setAttribute("src",  e.currentTarget.getAttribute("src"));
      }
    }
  },
  mounted() {
    this.activeKey=1;
  }
}
</script>

<style lang="scss">
.arco-tabs{
  height: 100%;
  width: 100%;
  .arco-tabs-content{
    height: 100%;
    width: 100%;
    .arco-tabs-content-list {
      height: calc(100% - 40px);
      width: 100%;

      .arco-tabs-content-item {
        height: 100%;
        width: 100%;
        .arco-tabs-pane {
          height: 100%;
          width: 100%;

          .mydeck {
            height: 100%;
            width: calc(100% - 300px);
            border: 0px;
          }

          .doclist {
            float: left;
            width: 300px;
            text-align: left;
          }
        }

      }
    }

  }

}

</style>
