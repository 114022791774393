<template>
  <div>
<!--    <config-provider/>-->
    <a-layout-header>
      <TopNavBar id="top-nav-bar" class="animate__animated animate__slideInDown animate__slow animate__repeat-1" :linklist="linklist"></TopNavBar>
    </a-layout-header>
    <a-layout-content>
      <router-view></router-view>
    </a-layout-content>
    <a-layout-footer>
      <BottomClaim class="bottom-claim animate__animated animate__fadeInUp animate__slower animate__repeat-1"></BottomClaim>
    </a-layout-footer>
    <Modal :visible="showModal" :title="ModalTitle" :msg="ModalMessage" :exec-o-k="execOK" :exec-cancel="execCancel"></Modal>
  </div>

</template>

<script>
import TopNavBar from "@/components/Element/Navi/Top/TopNavBar.vue";
import BottomClaim from "@/components/Element/BottomClaim.vue";
import Modal from "@/components/Element/Feedback/Modal.vue";
export default {
  name: 'App',
  components: {
    Modal,
    BottomClaim,
    TopNavBar,
    // ConfigProvider
  },
  data() {
    return {
      linklist:[{
        key:1,
        link:'/',
        name:'Home'
      },{
        key:2,
        link:'/personal-gallery',
        name:'Personal Gallery'
      },{
        key:3,
        link:'/about-me',
        name:'About Me'
      }],
      clicklink: "Index",
      showModal:false,
      ModalTitle:"",
      ModalMessage:"",
      execOK:()=>{},
      execCancel:()=>{}
    }
  },
  watch: {
    $route: function (to, from) {
      localStorage.setItem("$currentRouter", to.path);
    }
  },
  mounted() {
    window.addEventListener('popstate', (e)=>{
      // alert(1);
      this.handleBeforeUnload(e);
    });
  },
  beforeDestroy() {
    window.removeEventListener('popstate', (e)=>{
      this.handleBeforeUnload(e);
    });
  },
  methods: {
    handleBeforeUnload(e) {
      if (this.hasContent) { // hasContent为页面是否有内容的标志
        console.log("no action for relaod cancelled!");


      }

      // 在页面即将被卸载前触发，可以在这里添加需要执行的逻辑
      this.ModalTitle="确定刷新页面？";
      this.ModalMessage="本次刷新不会强制浏览器的页面刷新，如需强制刷新，请关闭浏览器标签页进行重新访问！";
      this.showModal = true;

      this.execOK = (e)=>{
        this.relaod();
        this.showModal=false;

      }
      this.execCancel=(e)=>{
        console.log("no action for relaod cancelled!");
        this.showModal=false;
      };
      e.preventDefault();
      e.returnValue = false;

    },
    relaod(){

    }
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  position: relative;
  #app {
    position: relative;
    height: 100%;
    width: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: url("assets/home/bg.png") 0 0 repeat;
    overflow: hidden;

    .arco-layout-header {
      width:100%;
      position: fixed;
      z-index: 1000;
      #top-nav-bar {
        .arco-menu {
          background: none;
          transition: background-color 2s;
          .arco-menu-item {
            :hover {
              background: none !important;
            }
          }
        }
      }
    }
    .arco-layout-content {
      overflow: hidden;
      height: 1000h;
    }
  }
}
</style>
