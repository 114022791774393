<script>

import PageNav from "@/components/Element/Navi/Left/PageNav.vue";
import NavPage_Page from "@/components/View/Template/NavPage_Page.vue";
export default {
  props:["navjson"],
  components:{
    PageNav
  }
}
</script>

<template>
  <div class="left">
    <page-nav class="animate__animated
                     animate__fadeInLeft
                     animate__slow
                     animate__repeat-1"
              :navjson="navjson"
    >
    </page-nav>
  </div>
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<style lang="scss" scoped>
.left{
  background: none;
  height: 90vh;
  margin-top:5vh;
  margin-bottom: 4vh;
  width: 10vw;
  float: left;
  border-right:0.5px solid #aaa;
  box-sizing: border-box;
}
.container{
  width: 90vw;
  float: left;
  padding: 68px 3vw 68px 1vw;
  box-sizing: border-box;
  height: 100vh;
}
</style>