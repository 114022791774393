<script>
import { IconExclamationCircleFill } from '@arco-design/web-vue/es/icon';
  export default {
    props:['msg'],
    components: {
      IconExclamationCircleFill
    },
  }
</script>

<template>
  <a-empty>
    <template #image>
      <icon-exclamation-circle-fill />
    </template>
    {{msg}}
  </a-empty>
</template>

<style>

</style>