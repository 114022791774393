<template>
  <div class="menu-demo">
    <a-menu
        :default-selected-keys="['1']"
        v-model:selected-keys="selectedKeys"
    >
      <NoSubNavItem v-for="item in navjson"
                    :itemid="item.key" :itemlink="item.path" :itemname="item.name"></NoSubNavItem>
    </a-menu>
  </div>
</template>
<script>
import NoSubNavItem from "@/components/Element/Navi/Left/NoSubNavItem.vue";
import Global from "@/Methods/Global.vue";

export default {
  components: {
    NoSubNavItem
  },
  props:["navjson"],
  data(){
    return{
      selectedKeys:"1"
    }
  },mounted(){
    var path = localStorage.getItem("$currentRouter");
    var key = Global.methods.findArrItemVal(this.navjson,path,"path","key");
    if(key){
      this.selectedKeys = ""+key;
    }else{
      this.selectedKeys = "1";
      window.location.href=this.navjson[0].path;
    }

  }
};
</script>
<style lang="scss" scoped>
.menu-demo {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: none;
  * {background: none}
  > *{background: none}
  .arco-menu{
    float: right;
    .arco-menu-item{}
  }
}
</style>
